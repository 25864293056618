import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import api from "../../helper/auth_interceptor";
import jwt_decode from "jwt-decode";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../helper/auth_helper";
import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import status from "../../images/status-icon.png";
import role from "../../images/role.png";
import date from "../../images/date-icon.png";
import edit from "../../images/edit.jpg";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast } from "react-toastify";
import createicon from "../../images/create-icon.png";
import "./manageUser.modules.css";
import moment from "moment";
import blackEdit from "../../images/blackedit.png";
import blackDelete from "../../images/blackDelete.png";
import searchbar from "../../images/search-bar.png";
import useExport from "../shared/useExport";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import deleteBtn from "../../images/trash.png";

function ManageUsers(suprmenAdmin) {
  const orgId = useRef("");
  const format = "DD-MM-YYYY";
  const navigate = useNavigate();

  const [showCalendar, setShowCalendar] = useState(false);
  const dateRangeRef = useRef(null);
  const checkOpt = {width:"3%"}
  const ForNameRow = {width:"20%",textAlign:"left"}
  const ForEmailRow = {width:"15%",textAlign:"left"}
  const ForRoleRow = {width:"15%",textAlign:"left"}
  const ForDateRow = {width:"15%",textAlign:"left"}
  const FororganizationRow = {width:"10%",textAlign:"left"}
  const ForStatusRow = {width:"10%",textAlign:"left"}

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [user, setUser] = useState("");
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortingDetails, setSortingDetails] = useState({
    column:null,
    order:null
  });
  const [isAscending, setIsAscending] = useState(true);

  const requestBody = useRef({
    organizationName: null,
    location: null,
    licenceType: null,
    startDate: null,
    endDate: null,
  });

   const toggleClearFilter = () => {
    window.location.reload();
  };

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchUserDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { handleExport } = useExport();

  const handleClickExport = async () => {
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/export/${orgId.current}?sortBy=${sortingDetails.column}&sortOrder=${sortingDetails.order}`;
    const fileName = "UsersList: ";
    handleExport(fileName, apiUrl, requestBody.current);
  };

  const currentPage = useRef(1);

  const pageNumber = currentPage.current;

  const selectedPageSize = useRef(10);

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchUserDetails(1);
  };

  async function fetchUserDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;

    const token = user.access_token;
    const decodedToken = jwt_decode(token);
    orgId.current = decodedToken.orgId;

    const selectedSize = selectedPageSize.current;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/filter/${orgId.current}?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });

      setUser(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchUserDetails(currentPage.current);
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleSort = async (column, page) => {
    const user = await getUser();
    const token = user.access_token;
    const decodedToken = jwt_decode(token);
    orgId.current = decodedToken.orgId;
    const pageForUrl = page || currentPage.current;
    const selectedSize = selectedPageSize.current;
    const newIsAscending = column === sortedColumn ? !isAscending : true;
    setSortedColumn(column);
    setIsAscending(newIsAscending);
    const sortOrder = newIsAscending ? "asc" : "desc";
    setSortingDetails({
      column: column,
      order: newIsAscending ? "asc" : "desc"
    })
    const sortApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/filter/${orgId.current}?pageNumber=${pageForUrl}&pageSize=${selectedSize}&sortBy=${column}&sortOrder=${sortOrder}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.post(sortApi, requestBody.current, {
        headers,
      });

      setUser(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditClick = (rowData) => {
    navigate("/editUser/" + rowData.userId, { state: { rowData: user } });
  };

  const handleDeleteUser = async (rowData) => {
    const userStatus = rowData.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const confirmDelete = window.confirm(
      `Are you sure you want this user to be ${userStatus}?`
    );
    if (confirmDelete) {
      if((suprmenAdmin?.suprmenAdmin || suprmenAdmin?.orgAdmin)){
        const user = await getUser();
          const delApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/status/${rowData.userId}?status=${userStatus}`;

        fetch(delApi, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(data => {
            if(data.status === 200){
              toast(`user status is changed to ${userStatus}!`);
              fetchUserDetails(currentPage.current);
            }
            else{
              toast.error(data?.statusText)
            }
          })
          .catch(error => {
            console.error('Error making PUT request:', error);
          });

      }
      else{
        toast("Only SuprMen or org admin can inactivate the user!");
      }
    }
  };

  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      startDate: startDate,
      endDate: endDate,
    };
    fetchUserDetails();
  };

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;

    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchUserDetails();
  };

  const onSelectStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      status: e.target.value === "" ? null : e.target.value,
    };
    fetchUserDetails();
  };

  const onSelectRole = (e) => {
    requestBody.current = {
      ...requestBody.current,
      role: e.target.value === "" ? null : e.target.value,
    };
    fetchUserDetails();
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
  };

  const getProjectStatusDisplayText = (role) => {
    const roleMapping = {
      SUPRMEN_ADMIN: "Suprmen Admin",
      ORG_ADMIN: "Organization Admin",
      REGULAR: "Regular",
      VIEWER: "Viewer",
    };
    return roleMapping[role] || role;
  };

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input id="slide-sidebar" className="checkInputManageUserList" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>

        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t('pageHeader_manageUsers')}</h1>
              </div>
              <div class="user">
                
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t('pageHeaderBtn_manageUsers')}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="userlist-record">
                  <div class="userlist-details">
                    <div className="form-head list-user">
                      <div>
                        <h2 className="pageHeading">{t('tableHeading_manageUsers')}</h2>
                      </div>
                     
                    </div>
                    <div class="userlist-details-content">
                      <div class="content-options">
                      <div class="search-option searchfilter">
                          <div class="search-input">
                            
                            <img src={searchbar} alt="home icon" />
                            <input
                            className="checkInputManageUserList"
                              type="text"
                              placeholder={t('search')}
                              value={requestBody.searchText}
                              onChange={handleSearchFormSubmit}
                            />
                          </div>
                        </div>

                        <div class="content-button search filterIconsMainDiv">
                          
                          <img src={status} alt="status icon" />
                          <span>
                            
                            <select
                              className="user-select"
                              onChange={(e) => onSelectStatus(e)}
                            >
                              
                              <option value="">{t('filter1_manageUsers')}</option>
                              <option value="ACTIVE">{t('active_dropDownMenu')}</option>
                              <option value="INACTIVE">{t('inactive_dropDownMenu')}</option>
                            </select>
                          </span>
                        </div>

                        <div class="content-button search filterIconsMainDiv">
                          
                          <img src={role} alt="role icon" />
                          <span>
                            
                            <select
                              className="user-select"
                              onChange={(e) => onSelectRole(e)}
                            >
                              
                              <option value="">{t('filterRole_manageUsers')}</option>
                              <option value="SUPRMEN_ADMIN">
                                {t('suprmenAdmin_dropDownMenu')}
                              </option>
                              <option value="ORG_ADMIN">{t('orgAdmin_dropDownMenu')}</option>
                              <option value="REGULAR">{t('regular_dropDownMenu')}</option>
                              <option value="VIEWER">{t('viewer_dropDownMenu')}</option>
                            </select>
                          </span>
                        </div>

                        <div class="content-button date filterIconsMainDiv">
                          <img src={date} alt="date icon"  className="dateicon"/>
                          <button
                            className="btn-date"
                            onClick={() => setShowCalendar(!showCalendar)}
                          >
                            <span>{t('dateFilter_manageUsers')}</span>
                          </button>
                          <div className="dateRange" ref={dateRangeRef}>
                            {showCalendar && (
                              <DateRangePicker

                                ranges={selectedDateRange}
                                onChange={handleDateChange}
                              />
                            )}
                          </div>
                        </div>
                        <div class="content-button clear filterIconsMainDiv">
                          
                          <button class="btn-clear" onClick={toggleClearFilter}>
                            <span>{t('clearFilter_manageUsers')}</span>
                          </button>
                        </div>
                        <div class="export">
                          <button className="btn" onClick={handleClickExport}>
                            <span>{t('exportBtn_manageUsers')}</span>
                          </button>
                        </div>
                      </div>

                      <div class="right-buttons">
                      <div class="create-user-btn">
                        
                        <button class="btn quoteBtns" type="button">
                          
                          <a href="/createUser" className="quotelink">
                            
                            <img src={createicon} alt="createIcon"></img><span>{t('tableHeadingBtn_manageUsers')}</span> 
                          </a>
                        </button>
                      </div>
                       
                      </div>
                    </div>
                    <div class="userlist-details-table-content">
                      <div class="table-content">
                        <div className="list">
                          <table>
                            <thead>
                              <tr className="record-row">
                                {/* <th class="checked-box" style={checkOpt}>
                                  
                                  <input
                                    class="form-check-input checkInputManageUserList"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked
                                  />
                                </th> */}
                                <th
                                style={ForNameRow}
                                  class="opac "
                                  onClick={() => handleSort("name")}
                                >
                                  
                                  {t("userName_table")}
                                  {sortedColumn === "name"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                style={ForEmailRow}
                                  class="opac "
                                  onClick={() => handleSort("email")}
                                >
                                  
                                  {t("email_table")}
                                  {sortedColumn === "email"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                style={ForDateRow}
                                  class="opac "
                                  onClick={() => handleSort("startDate")}
                                >
                                  
                                  {t("startDate_table")}
                                  {sortedColumn === "startDate"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                 style={ForDateRow}
                                  class="opac "
                                  onClick={() => handleSort("endDate")}
                                >
                                  
                                  {t("endDate_table")}
                                  {sortedColumn === "endDate"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                 style={ForRoleRow}
                                  class="opac"
                                  onClick={() => handleSort("role")}
                                >
                                  
                                 {t('role_table')}
                                  {sortedColumn === "role"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                {suprmenAdmin.suprmenAdmin ? <th
                                 style={FororganizationRow}
                                  class="opac "
                                  onClick={() => handleSort("organization")}
                                >
                                  
                                  {t('organisation_table')}
                                  {sortedColumn === "organization"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>: null}
                                <th
                                 style={ForStatusRow}
                                  class="opac "
                                  onClick={() => handleSort("status")}
                                >
                                  
                                  {t('status_table')}
                                  {sortedColumn === "status"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th className="action-buttons">
                                  {/* <div>
                                    
                                    <img src={blackEdit} alt="black edit"></img>
                                  </div> */}
                                  <div>
                                    
                                    <img src={deleteBtn} alt="black Delete"></img>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              {user.map((user) => (
                                <>
                                  <tr>
                                    {/* <td class="checked-box"  style={checkOpt}>
                                      
                                      <input
                                        class="form-check-input checkInputManageUserList"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                      />
                                    </td> */}
                                    <td  style={ForNameRow}>
                                      
                                      <a
                                        href="" onClick={() => handleEditClick(user)}
                                      >{user.firstName} {user.lastName}</a>
                                    </td>
                                    <td  style={ForRoleRow}>{user.email}</td>
                                    <td  style={ForDateRow}>
                                      
                                      {user.startDate
                                        ? moment(user.startDate).format(format)
                                        : "-"}
                                    </td>
                                    <td style={ForDateRow}>
                                      
                                      {user.endDate
                                        ? moment(user.endDate).format(format)
                                        : "-"}
                                    </td>
                                    <td style={ForRoleRow}>
                                      {user.role? getProjectStatusDisplayText(user.role): '-'}
                                    </td>
                                    { suprmenAdmin.suprmenAdmin ? <td>
                                      {user.organizationName? user.organizationName : '-'}
                                    </td> : null}
                                    <td style={ForDateRow}>
                                      {capitalizeFirstLetter(user.status)}
                                    </td>
                                    <td className="action-buttons">
                                      {/* <div>
                                        
                                        <a
                                          onClick={() => handleEditClick(user)}
                                        >
                                          
                                          <img src={edit} alt="edit"></img>
                                        </a>
                                      </div> */}
                                      <div>
                                        
                                        <a
                                          onClick={() => handleDeleteUser(user)}
                                        >
                                          
                                          <img style={{opacity:(user.status) === 'ACTIVE' ? "1" :"0.6"}} src={deleteBtn} alt="del btn"></img>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="userlist-details-table-pagination">
                      <div class="page-no">
                        <div>
                          <Pagination
                            count={pageNumbers}
                            page={pageNumber}
                            onChange={(_event, page) => handlePageChange(page)}
                          />
                        </div>
                      </div>
                      <div class="page-selection">
                        
                        <select
                          onChange={(e) => {
                            handlePageSize(e.target.value);
                          }}
                        >
                          
                          <option>{t('selectPageSize_table')}</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ManageUsers;
