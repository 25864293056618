import React, { useState, useEffect } from "react";
import { getUser } from "../../../../../helper/auth_helper";
import { useTranslation } from 'react-i18next';
import api from "../../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import "./editAddressModal.modules.css";
import bin from "../../../../../images/bin.png";


const EditAddressModal = ({proId, addedId, closeModal, getAddresses, editingAddress}) => {

    const [address, setAddress] = useState({
      streetName: "",
      city: "",
      postcode: "",
      houseNo: "",
      country: "",
      bagId: "",
       
});
const { t } = useTranslation();
const currentPage = 1;
const handleInputChange = (field, value) => {
    setAddress((prev) => ({ ...prev, [field]: value }));
  };

  const handleDelete = () => {
    setAddress({
        streetName: "",
        city: "",
        postcode: "",
        houseNo: "",
        country: "",
        bagId: "",
    });
    
  };
  const [validationErrors, setValidationErrors] = useState({});
console.log(validationErrors)
  const PutApi = async() => {
    const user = await getUser();
    
    
    const editedFields = Object.entries(address).filter(([key, value]) => {
      return value !== editingAddress[key] && value !== "";
  });

  if (editedFields.length > 0) {
      const requiredFields = ["houseNo", "streetName", "city", "postcode", "country"];
      const fieldLabels = {
          houseNo: t('houseNo'),
          streetName: t('streetName'),
          city: t('city'),
          postcode: t('postCode'),
          country: t('country'),
          bagId: t('bagId')
      };
      const newValidationErrors = {};
      let hasErrors = false;

      requiredFields.forEach((field) => {
          if (!address[field]) {
              newValidationErrors[field] = `${fieldLabels[field]} ${t('required_errorMessage')}`;
              hasErrors = true;
          }
      });

      if (hasErrors) {
          setValidationErrors(newValidationErrors);
          return;
      }
  }

  setValidationErrors({});


      const putApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/address/${addedId}`;
      
      const requestBody = {
        bagId: address.bagId,
        country: address.country,
        streetName: address.streetName,
        city: address.city,
        postcode: address.postcode,
        houseNo:address.houseNo,
        projectId:Number(proId)
        
      };
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      try {
        const response = await api.put(putApi, requestBody, {
          headers,
        });
        if (response) {
          toast(`${t('changesSaved')}`);
          closeModal();
          getAddresses("", currentPage)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  } 
  

  useEffect(() => {
    if (editingAddress) {
      setAddress(editingAddress);
    }
  }, [editingAddress]);

    
    
    return(
      <div class="userdiv">
      <div class="addressDiv-content">
         <table>
            <thead>
               <tr>
                  <th>{t('houseNo')} <span className="required">*</span></th>
                  <th>{t('streetName')}  <span className="required">*</span></th>
                  <th>{t('city')} <span className="required">*</span></th>
                  <th>{t('postCode')} <span className="required">*</span></th>
                  <th>{t('country')} <span className="required">*</span></th>
                  <th>{t('bagId')} </th>
                  <th className="deleteBtn">{t('delete')}</th>
               </tr>
            </thead>
            <tbody>
               <tr
                  >
                  <td>
                     <input
                     value={address.houseNo || ""}
                     onChange={(e) =>
                     handleInputChange("houseNo", e.target.value)
                     }
                     type="text"
                     maxLength={50}
                     className={validationErrors.houseNo ? "input-error" : ""}
                     ></input>
                        {validationErrors.houseNo && <span className="error-message">{validationErrors.houseNo}</span>}
                  </td>
                  <td>
                     <input
                     value={address.streetName || ""}
                     onChange={(e) =>
                     handleInputChange("streetName", e.target.value)
                     }
                     type="text"
                     maxLength={50}
                     className={validationErrors.streetName ? "input-error" : ""}
                     ></input>
                      {validationErrors.streetName && <span className="error-message">{validationErrors.streetName}</span>}
                  </td>
                  <td>
                     <input
                     value={address.city || ""}
                     onChange={(e) =>
                     handleInputChange("city", e.target.value)
                     }
                     type="text"
                     maxLength={50}
                     className={validationErrors.city ? "input-error" : ""}
                     ></input>
                     {validationErrors.city && <span className="error-message">{validationErrors.city}</span>}
                  </td>
                  <td>
                     <input
                     value={address.postcode || ""}
                     onChange={(e) =>
                     handleInputChange("postcode", e.target.value)
                     }
                     type="text"
                     maxLength={50}
                     className={validationErrors.postcode ? "input-error" : ""}
                     ></input>
                      {validationErrors.postcode && <span className="error-message">{validationErrors.postcode}</span>}
                  </td>
                  <td>
                     <input
                     value={address.country || ""}
                     onChange={(e) =>
                     handleInputChange("country", e.target.value)
                     }
                     type="text"
                     maxLength={50}
                     className={validationErrors.country ? "input-error" : ""}
                     ></input>
                      {validationErrors.country && <span className="error-message">{validationErrors.country}</span>}
                  </td>
                  <td>
                     <input
                     value={address.bagId || ""}
                     onChange={(e) =>
                     handleInputChange("bagId", e.target.value)
                     }
                     type="text"
                     maxLength={50}
                     className={validationErrors.bagId ? "input-error" : ""}
                     ></input>
                     {validationErrors.bagId && <span className="error-message">{validationErrors.bagId}</span>}
                  </td>
                  <td>
                     <button
                        className="btn btn-light delete-button"
                        onClick={() => handleDelete()}
                     >
                     <img src={bin} alt="navigation-icon" />
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
      <button className="btn btn-success modal-save-button" onClick= {PutApi}>
      {t('saveBtn')}
      </button>
 
   </div>
    )
}

export default EditAddressModal;