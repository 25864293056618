/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import api from "../../helper/auth_interceptor";
import { useTranslation } from 'react-i18next';
import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import edit from "../../images/edit.jpg";
import { getUser, login } from "../../helper/auth_helper";
import createicon from "../../images/create-icon.png";
import status from "../../images/status-icon.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./quoteView.modules.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import blackEdit from "../../images/blackedit.png";
import hamburger from "../../images/hamburger.jpg";
import date from "../../images/date-icon.png";
import searchbar from "../../images/search-bar.png";
import shareBtn from "../../images/shareBtn.png";
import blackDelete from "../../images/blackDelete.png";
import deleteBtn from "../../images/trash.png";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

function QuoteView({ suprmenAdmin, orgAdmin, viewer, regular }) {
  const partnerList = [];
  const [showCalendar, setShowCalendar] = useState(false);
  const [partnerResponse, setPartnerResponse] = useState([]);
  const shareIconImg = {
    padding: "0 1px",
  };
  const checkOpt = { width: "5%" }
  const ForNameRow = { width: "30%", textAlign: "left" }
  const ForStatusRow = { width: "20%", textAlign: "left" }
  const ForDateRow = { width: "20%", textAlign: "left" }
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { t } = useTranslation();
  const [quote, setQuote] = useState([]);
  const [isExternalPartner, setIsExternalPartner] = useState(false);
  const navigate = useNavigate();
  const dateRangeRef = useRef(null);

  

  const requestBody = useRef({
    searchText: null,
    status: undefined,
    startDate: "",
    endDate: "",
  });

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchQuoteDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);

  const pageNumber = currentPage.current;

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchQuoteDetails();
  };

  async function quotePartnerDetails(id) {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/quotePartnersByUserEmail/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });
      console.log(response.data);
      return response.data;
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchQuoteDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    if (!user) {
      login();
      return;
    }
    const selectedSize = selectedPageSize.current;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/filter/search?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });
      setIsExternalPartner(response.data.isExternalPartner);
      setQuote(response.data.quoteList);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }



  useEffect(() => {
    fetchQuoteDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  const handleEditClick = async (rowData) => {
    try {
      

      const partnerResponse = await quotePartnerDetails(rowData.id);
    
      const mappedItems =
        partnerResponse && partnerResponse.map((item) => item.partnerType);
      console.log("partnerResponse:", partnerResponse);
      console.log(mappedItems);
      const isExternal =
        mappedItems &&
        mappedItems.length === 1 &&
        mappedItems.includes("DRONE_OPERATOR");

      if (isExternal) {
        navigate("/dspView/" + rowData.id, { state: { rowData: quote } });
      } else {
        navigate("/editQuote/" + rowData.id, { state: { rowData: quote } });
      }
    } catch (error) {
      console.error("Error fetching partner details:", error);
     
    }
  };

  if (!quote) {
    return <div>Loading...</div>;
  }

  const handleDateFormSubmit = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      desiredStartDateFrom: startDate,
      desiredDeliveryDateTo: endDate,
    };
    fetchQuoteDetails();
  };

  const handleSearchFormSubmit = (text) => {
    requestBody.current = {
      ...requestBody.current,
      searchText: text,
    };
    fetchQuoteDetails();
  };

  const onSelectStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      status: e.target.value ? e.target.value : null,
    };
    fetchQuoteDetails();
  };

  const getProjectStatusDisplayText = (status) => {
    const statusMapping = {
      DRAFT: "Draft",
      REQUESTED: "Requested",
      SENT: "Sent",
      REJECTED: "Rejected",
      ACCEPTED: "Accepted",
      QUOTE_FORWARDED: "Forwarded",
      OFFER_SELECTED: "Selected",
    };
    return statusMapping[status] || status;
  };

  const onSelectPartnerStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      quotePartnerStatus: e.target.value ? e.target.value : null,
    };
    fetchQuoteDetails();
  };
  
  const getQuotePartnerStatusDisplayText = (status) => {
    const statusMapping = {
      QUOTE_RECEIVED: "Received",
      OFFER_SENT: "Offer Sent",
      REJECTED: "Rejected",
      ACCEPTED: "Accepted"
    };
    return statusMapping[status] || status;
  };


    return (
    <div class="main-wrap">
      <div className="quote-body">
        <input
          id="slide-sidebar"
          type="checkbox"
          className="checkInputQuoteView"
          role="button"
        />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t('pageHeader_qouteViewPage')}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t('pageHeaderBtn_quoteViewPage')}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="quotelist-record">
                  <div class="quotelist-details">
                    <div className="form-head list-quote">
                      <div>
                        <h2 className="pageHeading">{t('tableHeadingBtn_quoteViewPage')}</h2>
                      </div>
                     
                    </div>
                    <div class="quotelist-details-content">
                      <div class="content-options">
                      <div class="search-option searchfilter">
                          <div class="search-input">
                            <img src={searchbar} alt="home icon" />
                            <input
                              className="checkInputQuoteView"
                              type="text"
                              placeholder={t('search')}
                              value={requestBody.searchText}
                              onChange={(e) =>
                                handleSearchFormSubmit(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div class="content-button search filterIconsMainDiv">
                          <img src={status} alt="status icon" />
                          { !isExternalPartner && <span>
                            <select
                              className=""
                              onChange={(e) => onSelectStatus(e)}
                            >
                              <option value="">{t('filter1_quoteViewPage')}</option>

                              <option value="DRAFT">{t('draft__dropDownMenu')}</option>
                              <option value="REQUESTED">{t('requested_dropDownMenu')}</option>
                              <option value="QUOTE_FORWARDED">{t('forwarded__dropDownMenu')}</option>
                              <option value="ACCEPTED">{t('accepted__dropDownMenu')}</option>
                              <option value="REJECTED">{t('rejected__dropDownMenu')}</option>
                              <option value="OFFER_SELECTED">{t('selected_dropDownMenu')}</option>
                            </select>
                          </span>}

                          {isExternalPartner && <span>
                            <select
                              className=""
                              onChange={(e) => onSelectPartnerStatus(e)}
                            >
                              <option value="">{t('status___dropDownMenu')}</option>

                              <option value="OFFER_SENT">{t('offerSent__dropDownMenu')}</option>
                              <option value="QUOTE_RECEIVED">{t('recieved_dropDownMenu')}</option>
                              <option value="ACCEPTED">{t('accepted__dropDownMenu')}</option>
                              <option value="REJECTED">{t('rejected__dropDownMenu')}</option>
                            </select>
                          </span>}
                        </div>

                        <div class="content-button date filterIconsMainDiv">
                          <img
                            src={date}
                            alt="date icon"
                            className="dateicon"
                          />
                          <button
                            className="btn-date"
                            onClick={() => setShowCalendar(!showCalendar)}
                          >
                            <span>{t('dateFilter_quoteViewPage')}</span>
                          </button>
                          <div className="dateRange" ref={dateRangeRef}>
                            {showCalendar && (
                              <DateRangePicker
                                ranges={selectedDateRange}
                                onChange={handleDateFormSubmit}
                              />
                            )}
                          </div>
                        </div>
                        <div class="content-button clear filterIconsMainDiv">
                          <button
                            class="btn-clear"
                            onClick={() => window.location.reload()}
                          >
                            <span>{t('clearFilter_quoteViewPage')}</span>
                          </button>
                        </div>
                      </div>
                      <div class="right-buttons">
                      {suprmenAdmin && (
                      <div class="create-quote-btn">
                        <button class="btn quoteBtns" type="button">
                          <a href="/quotecreation" className="quotelink">
                            <img src={createicon} alt="creat icon"></img> <span>{t('tableHeadingBtn_quoteViewPage')} </span> 
                          </a>
                        </button>
                      </div>)}
                      </div>
                    </div>
                    <div class="quotelist-details-table-content">
                      <div class="table-content">
                        <div className="list">
                          <table>
                            <thead>
                              <tr className="record-row">
                                <th class="opac " style={ForNameRow}>
                                  {t('clientName_table')}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th class="opac " style={ForNameRow}>
                                {t('projectName_table')}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                { !isExternalPartner && 
                                <th class="opac " style={ForStatusRow}>
                                 {t('quoteStatus_table')} 
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>}
                                { isExternalPartner && 
                                <th class="opac " style={ForStatusRow}>
                                  {t('status_table')}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>}
                                <th class="opac " style={ForDateRow}>
                                {t('expectedDeliveryDate_table')}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th className="action-buttons">
          
                                  <div>
                                    <img
                                      class="opac-img"
                                      alt="opac img"
                                      src={deleteBtn}
                                    ></img>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {quote.map((quote) => (
                                <>
                                  <tr>
                                    <td style={ForNameRow}>
                                      <a
                                        className="redirectBtn"
                                        onClick={() => {
                                          handleEditClick(quote);
                                        }}
                                      >
                                        {quote.project.organization.name}
                                      </a>
                                    </td>
                                    <td style={ForNameRow}>{quote?.project?.name}</td>
                                    {!isExternalPartner && <td style={ForStatusRow}>
                                      {getProjectStatusDisplayText(
                                        quote.quoteStatusType
                                      )}
                                    </td>}
                                    {isExternalPartner && <td style={ForStatusRow}>
                                      {getQuotePartnerStatusDisplayText(
                                        quote.quotePartnerStatus
                                      )}
                                    </td>}
                                    <td style={ForDateRow}>{quote.project.endDate}</td>

                                    <td className="action-buttons">
                                      
                                      <div>
                                        <a>
                                          <img
                                            src={deleteBtn}
                                            alt="delete btn"
                                          ></img>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="quotelist-details-table-pagination">
                      <div class="page-no">
                        <div>
                          <Pagination
                            count={pageNumbers}
                            page={pageNumber}
                            onChange={(_event, page) => handlePageChange(page)}
                          />
                        </div>
                      </div>
                      <div class="page-selection">
                        <select
                          className=""
                          onChange={(e) => {
                            handlePageSize(e.target.value);
                          }}
                        >
                          <option>{t('selectPageSize_table')}</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteView;
