import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import api from "../../helper/auth_interceptor";
import "./createOrganisation.modules.css";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import plus from "../../images/Frame.png";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import { getUser, login } from "../../helper/auth_helper";
import "react-datepicker/dist/react-datepicker.css";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

function CreateOrganisation({ suprmenAdmin, orgAdmin, viewer, regular }) {
  const [userDetails, setUserDetails] = useState({
    organizationType: "",
    lastName: "",
    firstName: "",
    orgName: "",
    emailId: "",
    contactNo: "",
    licenceId: null,
    houseNo: null,
    streetName: "",
    city: "",
    postcode: "",
    bundleIdList: [1],
    discount: null,
    inactiveBuild: null,
  });
  const [licenceData, setLicenceData] = useState([]);
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState("BasicBundle");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState('');
  const format1 = "DD-MM-YYYY";
  const sDate = moment(startDate).format(format1);
  const eDate = moment(endDate).format(format1);
  const navigate = useNavigate();
  const now = moment();
  const todayDate = now.format("DD-MM-YYYY");
  const [validationErrors, setValidationErrors] = useState({});

  

  const [selectedLicenses, setSelectedLicenses] = useState(["BasicBundle"]);

  const [buttonTextMap, setButtonTextMap] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClicks = (e, licenseType) => {
    e.preventDefault();

    const licenseId = licenceData.find(
      (license) => license.name === licenseType
    ).id;
    const isActivated = userDetails.bundleIdList.includes(licenseId);

    const updatedBundleList = isActivated
      ? userDetails.bundleIdList.filter((bundleId) => bundleId !== licenseId)
      : [...userDetails.bundleIdList, licenseId];

    setUserDetails({
      ...userDetails,
      bundleIdList: updatedBundleList,
    });

    setButtonTextMap((prevMap) => ({
      ...prevMap,
      [licenseType]: isActivated ? "Activate" : "Activated",
    }));
  };

  const defaultClicks = (e) => {
    e.preventDefault();
    toast.error(`${selectedPlan} is already activated!`);
  };

  const getLicence = async () => {
    const user = await getUser();

    if (!user) {
      login();
      return;
    }

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/bundle`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setLicenceData(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      } else {
        toast.error("Error inviting user. Please try again.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await getUser();

    const requiredFields = [
      "organizationType",
      "lastName",
      "firstName",
      "orgName",
      "emailId",
      "contactNo",
      "houseNo",
      "streetName",
      "city",
      "postcode",
    ];

    const fieldLabels = {
      lastName: t('tabTwoLastName_createOrg'),
      firstName: t('tabTwoFirstName_createOrg'),
      organizationType: t('tabOneOrgType_createOrg'),
      emailId: t('tabTwoEmailAddress_createOrg'),
      orgName: t('tabOneOrgName_createOrg'),
      contactNo: t('tabTwoPhoneNo_createOrg'),
      houseNo: t('tabOneHouseNo_createOrg'),
      streetName: t('tabOneStreetName_createOrg'),
      city: t('tabOneCity_createOrg'),
      postcode: t('tabOnePostCode_createOrg')
      
    };

    const newValidationErrors = {};
    let hasErrors = false;
  
    requiredFields.forEach((field) => {
      if (!userDetails[field]) {
        newValidationErrors[field] = `${fieldLabels[field]} ${t('required_errorMessage')}`;
        hasErrors = true;
      }
    });
  
   
    if (!endDate) {
      newValidationErrors["endDate"] = `${t('endDate')} ${t('required_errorMessage')}`;
      hasErrors = true;
    }
  
    
    if (moment(endDate).isSameOrBefore(startDate)) {
      newValidationErrors["endDate"] =
        `${t('endDate_errorMessage')}`;
      hasErrors = true;
    }
  
 
    setValidationErrors(newValidationErrors);
  
    
    if (hasErrors) {
      return;
    }
    setLoading(true);
    
    const requestBody = {
      organizationType: userDetails.organizationType,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
      orgName: userDetails.orgName,
      emailId: userDetails.emailId,
      contactNo: userDetails.contactNo,
      houseNo: userDetails.houseNo,
      streetName: userDetails.streetName,
      city: userDetails.city,
      postcode: userDetails.postcode,
      startDate: sDate,
      bundleIdList: userDetails.bundleIdList,
      endDate: eDate,
      discount: userDetails.discount,
      minInactiveBuildingPercent: userDetails.inactiveBuild,
    };

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/organization`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try{
      const response = await api.post(apiUrl, requestBody, {
        headers,
      });
      if (response) {
        toast(`${t('orgCreated')}`);
        navigate("/manageOrganization");
      }
    } catch (error) {
      if (error.response.status === 409 && error.response.data.message === 'ERROR: duplicate key value violates unique constraint \"uk_8j5y8ipk73yx2joy9yr653c9t\"\n  Detail: Key (name)=(' + requestBody.orgName + ') already exists.') {
        toast.error(`${('organizationUniqueConstraint_errorMessage')}`);
      } 
       else if (error.response.status === 409 && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }  else {
        toast.error(`${t('errorInviting_errorMessage')}`);
      }
     
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneNoChange = (inputValue) => {
    const validPhoneNo = /^[\d+()-]*$/.test(inputValue);

    if (validPhoneNo || inputValue === "") {
      setUserDetails({
        ...userDetails,
        contactNo: inputValue,
      });
    } else {
      const newValidationErrors = {};
      newValidationErrors["contactNo"] =
      `${t('phoneNo_errorMessage')}`;
      setValidationErrors(newValidationErrors);
    }
  };

  useEffect(() => {
    getLicence();
    if (viewer||regular) {
      
      toast.error(`${t('permission_errorMessage')}`);
      navigate("/"); 
    }
  }, [viewer, navigate]);
 

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img className="sidebar-toggle" alt="sidebar" src={hamburger}></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>

          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>{t('pageHeader_createOrg')}</h1>
                  <div class="widget-button">
                 
                    {/* <button className="btn quoteBtns" type="button">
                      <a href="" className="quotelink">
                      
                        <img src={plus} alt="plus icon" />
                        <span>Add new Widget</span>
                      </a>
                    </button> */}
                  </div>
                </div>
                <div class="user">
                  
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t('pageHeaderBtn_createOrg')}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="organization-details-record">
                    <div class="organization-details">
                      <div className="form-head org-details">
                        <h2 className="pageHeading">{t('tabOneHeader_createOrg')}</h2>
                      </div>
                      <div class="organization-details-content">
                        <div class="first-row">
                          <div class={`rows-content ${
                                validationErrors.orgName ? "error" : ""
                              }`}>
                            
                            <label>
                             {t('tabOneOrgName_createOrg')}
                              <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.orgName}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  orgName: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.orgName && (
                                <span className="error-message">
                                  {validationErrors.orgName}
                                </span>
                              )}
                          </div>
                          <div class={`rows-content ${
                                validationErrors.organizationType ? "error" : ""
                              }`}>
                          
                            <label>
                              {t('tabOneOrgType_createOrg')}
                              <span className="required">*</span>
                            </label>
                            <div>
                              
                              <select
                                className="form-control"
                                value={userDetails.organizationType}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    organizationType: e.target.value,
                                  })
                                }
                              >
                                
                                <option>{t("tabOnePlaceholder_createOrg")}</option>
                                <option value="INSPECTION">
                           
                                  {t('inspection__dropDownMenu')}
                                </option>
                                <option value="DRONEOPERATOR">
                                
                                  {t('droneOperator_dropDownMenu')}
                                </option>
                                <option value="MAINTENANCE">
                                
                                  {t('maintenance_dropDownMenu')}
                                </option>
                                <option value="OWNER"> 
                                  
                                  {t('owner__dropDownMenu')} </option>
                                <option value="DIVERSE"> 
                               
                                 {t('diverse__dropDownMenu')} </option>
                              </select>
                              {validationErrors.organizationType && (
                                <span className="error-message">
                                  {validationErrors.organizationType}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="second-row">
                          <div class={`rows-content ${
                                validationErrors.houseNo ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabOneHouseNo_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.houseNo}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  houseNo: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.houseNo && (
                                <span className="error-message">
                                  {validationErrors.houseNo}
                                </span>
                              )}
                          </div>
                          <div class={`rows-content ${
                                validationErrors.streetName ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabOneStreetName_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.streetName}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  streetName: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.streetName && (
                                <span className="error-message">
                                  {validationErrors.streetName}
                                </span>
                              )}
                          </div>
                        </div>
                        <div class="third-row">
                          <div class={`rows-content ${
                                validationErrors.city ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabOneCity_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.city}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  city: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.city && (
                                <span className="error-message">
                                  {validationErrors.city}
                                </span>
                              )}
                          </div>
                          <div class={`rows-content ${
                                validationErrors.postcode ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabOnePostCode_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.postcode}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  postcode: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.postcode && (
                                <span className="error-message">
                                  {validationErrors.postcode}
                                </span>
                              )}
                          </div>
                        </div>
                        <div class="fourth-row">
                          <div className="rows-content">
                            <label>{t('tabOneStartDate_createOrg')}<span className="required">*</span></label>
                            <div className="date form-control">
                              <DatePicker
                                class="form-control"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />
                            </div>
                          </div>
                          <div class={`rows-content ${
                                validationErrors.endDate ? "error" : ""
                              }`}>
                            
                            <label>{t('tabOneEndDate_createOrg')} <span className="required">*</span></label>
                            <div className="date form-control">
                              <DatePicker
                                class="form-control"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                            {validationErrors.endDate && (
                                <span className="error-message">
                                  {validationErrors.endDate}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="admin-organization-record">
                    <div class="admin-details">
                      <div className="form-head admin-details">
                        <h2 className="pageHeading">{t('tabTwoHeader_createOrg')}</h2>
                      </div>
                      <div class="admin-leaderdetails-content">
                        <div class="first-row">
                          <div class={`rows-content ${
                                validationErrors.firstName ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabTwoFirstName_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.firstName}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  firstName: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.firstName && (
                                <span className="error-message">
                                  {validationErrors.firstName}
                                </span>
                              )}
                          </div>
                          <div class={`rows-content ${
                                validationErrors.lastName ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabTwoLastName_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.lastName}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  lastName: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.lastName && (
                                <span className="error-message">
                                  {validationErrors.lastName}
                                </span>
                              )}
                          </div>
                        </div>
                        <div class="second-row">
                          <div class={`rows-content ${
                                validationErrors.emailId ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabTwoEmailAddress_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.emailId}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  emailId: e.target.value,
                                })
                              }
                              type="text"
                            />
                            {validationErrors.emailId && (
                                <span className="error-message">
                                  {validationErrors.emailId}
                                </span>
                              )}
                          </div>
                          <div class={`rows-content ${
                                validationErrors.contactNo ? "error" : ""
                              }`}>
                            
                            <label>
                              {t('tabTwoPhoneNo_createOrg')} <span className="required">*</span>
                            </label>
                            <input
                              class="form-control"
                              value={userDetails.contactNo}
                              onChange={(e) =>
                                handlePhoneNoChange(e.target.value)
                              }
                              type="text"
                              maxLength={18}
                            />
                            {validationErrors.contactNo && (
                                <span className="error-message">
                                  {validationErrors.contactNo}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="plan-organization-record">
                    <div class="plan-details">
                      <div className="form-head-organisation">
                        <div class="plan-heading">
                          <h2 className="pageHeading right-plan">
                            
                            {t('tabThreeHeader_createOrgEditOrg')}
                          </h2>
                          <p>
                            {t('tabThreeHeadDescription_createOrgEditOrg')}
                          </p>
                        </div>
                      </div>
                      <div class="organization-content right-plan">
                        
                        <div className="row price-row">
                          
                          {licenceData.map((licence) => (
                            <div
                              key={licence.id}
                              className="col-lg-2 col-md-2 col-sm-2 content"
                            >
                              <h5 className="pricingHeading">{licence.name}</h5>
                              <p className="amount">
                                <h3>€{licence.pricePerBucket}</h3>/{t('year_bndl')}
                              </p>
                              <ul className="tick-list">
                                <li>
                                {t('buildings_bndl')}: {licence.numberOfBuildingsInBucket}
                                </li>
                                <li>
                                {t('price/Building_bndl')}: €
                                  {licence.avgPricePerBuildingPerYear}
                                </li>
                              </ul>
                              {selectedLicenses.includes(licence.name) ? (
                                <button
                                  className="btn btn-success activebtn"
                                  onClick={(e) => defaultClicks(e)}
                                >
                                  Activated
                                </button>
                              ) : (
                                <button
                                  className={`btn ${
                                    userDetails.bundleIdList.includes(
                                      licenceData.find(
                                        (license) =>
                                          license.name === licence.name
                                      ).id
                                    )
                                      ? "btn-success activebtn"
                                      : "btn-outline-dark activebtn"
                                  }`}
                                  onClick={(e) => {
                                    handleClicks(e, licence.name);
                                    setButtonTextMap((prevMap) => ({
                                      ...prevMap,
                                      [licence.name]:
                                        userDetails.bundleIdList.includes(
                                          licenceData.find(
                                            (license) =>
                                              license.name === licence.name
                                          ).id
                                        )
                                          ? "Activate"
                                          : "Activated",
                                    }));
                                  }}
                                >
                                  {buttonTextMap[licence.name] || "Activate"}
                                </button>
                              )}
                            </div>
                          ))}
                          <div className="discountDiv">
                            <h5>{t('tabThreeInactiveBuildings_createOrgEditOrg')}</h5>
                            <input
                              placeholder="1-100%"
                              className="form-control inputWidth"
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  inactiveBuild: e.target.value,
                                })
                              }
                              type="text"
                            ></input>
                          </div>
                          <div className="discountDiv">
                            <h5>{t('tabThreeDiscount_createOrgEditOrg')}</h5>
                            <input
                              placeholder="1-100%"
                              className="form-control inputWidth"
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  discount: e.target.value,
                                })
                              }
                              type="text"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="organization-plan plan-subscription">
                    <div>
                      <h4> {t('text1_createOrg')} {selectedPlan} plan </h4>
                      <p>
                        
                        <span>{t('text1_bndl')}</span>
                        {todayDate}.
                      </p>
                    </div>
                  </div>
                  <div class="bottom-button">
                    
                    <button
                      className="btn-back"
                      onClick={() => navigate("/manageOrganization")}
                    >
                      
                      <span>{t('backBtn')}</span>
                    </button>
                    <button
                      type="submit"
                      className="btn-save"
                      onClick={handleSubmit}
                    >
                      
                      <span>{t('saveBtn')} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateOrganisation;
