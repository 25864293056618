import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import api from "../../helper/auth_interceptor";
import { handleSort } from "./handleSort";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../helper/auth_helper";
import rightArrow from "../../images/right-arrow.png";
import edit from "../../images/edit.jpg";
import deleteBtn from "../../images/trash.png";
import home from "../../images/home.png";
import searchbar from "../../images/search-bar.png";
import createicon from "../../images/create-icon.png";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./projectList.modules.css";
import moment from "moment";
import blackEdit from "../../images/blackedit.png";
import blackDelete from "../../images/blackDelete.png";
import shareBtn from "../../images/shareBtn.png";
import useExport from "../shared/useExport";
import status from "../../images/status-icon.png";
import date from "../../images/date-icon.png";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import hamburger from "../../images/hamburger.jpg";

function ProjectList({suprmenAdmin, orgAdmin, viewer, regular}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const shareIconImg ={ padding: "0 1px"}
  const checkOpt = {width:"5%"}
  const ForNameRow = {width:"25%",textAlign:"left"}
  const ForRoleRow = {width:"20%",textAlign:"left"}
  const ForDateRow = {width:"15%",textAlign:"left"}
  const ForLastButtons = {width:"20%"}
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const dateRangeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [project, setProject] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSortWrapper = async (column, page) => {
    await handleSort(
      column,
      page,
      sortedColumn,
      isAscending,
      setSortedColumn,
      setIsAscending,
      currentPage,
      selectedPageSize,
      requestBody,
      setProject,
      setPageNumbers
    );
  };

  const requestBody = useRef({
    status: null,
    startDate: null,
    endDate: null,
    searchText: null,
  });

  const [sortedColumn, setSortedColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(true);

 ;

  const toggleClearFilter = () => {
    window.location.reload();
  };

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchProjectDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);

  const pageNumber = currentPage.current;

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchProjectDetails();
  };

 

  async function fetchProjectDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    const selectedSize = selectedPageSize.current;

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/filter?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });
      
      setProject(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const { handleExport } = useExport();
  async function exportFunction() {
    const sortedOrder = isAscending ? "asc" : "desc"
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/export-project-list?sortBy=${sortedColumn}&sortOrder=${sortedOrder}`;
    const fileName = "ProjectList_ " + new Date().toLocaleDateString();
    handleExport(fileName, apiUrl, requestBody.current);
  }

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  if (!project) {
    return <div>Loading...</div>;
  }

  const handleEditClick = (rowData) => {
    if(!rowData.viewable){
      return;
    }
    navigate("/editProject/" + rowData.id, { state: { rowData: project } });
  };

  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      startDate: startDate,
      endDate: endDate,
    };
    fetchProjectDetails();
  };

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;

    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchProjectDetails();
  };

  const onSelectStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      status: e.target.value,
    };
    fetchProjectDetails();
  };

  const getProjectRoleDisplayText = (role) => {
    const roleMapping = {
      SUPRMEN_ADMIN: t('supRmen_admin'),
      PROJECT_VIEWER: t('project_viewer'),
      PROJECT_USER: t('project_user'),
      PROJECT_LEADER : t('project_leader'),
    };
    return roleMapping[role] || role;
  };

  // const getProjectStatusDisplayText = (status) => {
  //   const statusMapping = {
  //     PROJECT_READY: "Ready",
  //     PROJECT_STARTED: "Started",
  //     PROJECT_COMPLETED: "Completed",
  //   };
  //   return statusMapping[status] || status;
  // };

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input id="slide-sidebar" className="checkInputProjectList" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t('pageHeader_projectList')}</h1>
              </div>
              <div class="user">
                
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t('pageHeaderBtn_projectList')}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="projectlist-record">
                  <div class="projectlist-details">
                    <div className="form-head list-project">
                      <div>
                        <h2 className="pageHeading">{t('tableHeading_projectList')}</h2>
                      </div>
                     
                    </div>
                    <div class="projectlist-details-content">
                      <div class="content-options">
                        {/* <div class="content-button search filterIconsMainDiv">
                          
                          <img src={status} alt="status icon" />
                          <span>
                            <select
                              className=""
                              onChange={(e) => onSelectStatus(e)}
                            >
                              <option value="default">Status</option>
                              <option value="PROJECT_READY">Ready</option>
                              <option value="PROJECT_STARTED">Started</option>
                              <option value="PROJECT_COMPLETED">
                                Completed
                              </option>
                            </select>
                          </span>
                        </div> */}

                        <div class="search-option searchfilter ">
                          <div class="search-input">
                            
                            <img src={searchbar} alt="home icon" />
                            <input
                            className="checkInputProjectList"
                              type="text"
                              placeholder={t('search')}
                              value={requestBody.searchText}
                              onChange={handleSearchFormSubmit}
                            />
                          </div>
                        </div>


                        <div class="content-button date filterIconsMainDiv">
                          <img src={date} alt="date icon" className="dateicon" />
                          <button
                            className="btn-date"
                            onClick={() => setShowCalendar(!showCalendar)}
                          >
                            <span>{t('dateFilter_projectList')}</span>
                          </button>
                          <div className="dateRange"  ref={dateRangeRef}>
                            {showCalendar && (
                              <DateRangePicker
                                ranges={selectedDateRange}
                                onChange={handleDateChange}
                              />
                            )}
                          </div>
                        </div>
                        <div class="content-button clear filterIconsMainDiv">
                          
                          <button class="btn-clear" onClick={toggleClearFilter}>
                            <span>{t('clearFilter_projectList')}</span>
                          </button>
                        </div>
                        <div class="export">
                          <button className="btn" onClick={exportFunction}>
                            <span>{t('exportBtn_projectList')}</span>
                          </button>
                        </div>
                      </div>
                      <div class="right-buttons">
                      {!viewer && !regular && (suprmenAdmin || orgAdmin) ? (
                      <div class="create-project-btn">
                        
                        <button class="btn quoteBtns" type="button">
                          
                          <a href="/project" className="quotelink">
                            
                            <img src={createicon} alt="create icon"></img><span>{t('tableHeadingBtn_projectList')}</span> 
                          </a>
                        </button>
                      </div>
                          ) : null}
                        
                      </div>
                    </div>
                    <div class="projectlist-details-table-content">
                      <div class="table-content">
                        <div className="list">
                          <table>
                            <thead>
                              <tr className="record-row">
                                {/* <th class="checked-box" style={checkOpt}>
                                  
                                  <input
                                  
                                    class="form-check-input checkInputProjectList"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked
                                  />
                                </th> */}
                                <th
                                style={ForNameRow}
                                  class="opac "
                                  onClick={() =>
                                    handleSortWrapper("projectName")
                                  }
                                >
                                  
                                  {t('projectName_table')}
                                  {sortedColumn === "projectName"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                    <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                    </div>
                                </th>
                                <th
                                style={ForNameRow}
                                  class="opac "
                                  onClick={() => handleSortWrapper("orgName")}
                                >
                                  
                                  {t('organisationName_table')}
                                  {sortedColumn === "orgName"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}<div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                    </div>

                                </th>
                                <th
                                style={ForRoleRow}
                                  class="opac "
                                  onClick={() => handleSortWrapper("role")}
                                >
                                  
                                  {t('projectRole_table')}
                                  {sortedColumn === "role"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                    <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                    </div>
                                </th>
                                <th
                                style={ForDateRow}
                                  class="opac "
                                  onClick={() => handleSortWrapper("startDate")}
                                >
                                  
                                  {t('startDate_table')}
                                  {sortedColumn === "startDate"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                    <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                    </div>
                                </th>
                                <th
                                 style={ForDateRow}
                                  class="opac "
                                  onClick={() => handleSortWrapper("endDate")}
                                >
                                  
                                  {t('endDate_table')}
                                  {sortedColumn === "endDate"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                    <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                    </div>
                                   
                                </th>
                                {/* <th
                                 style={ForDateRow}
                                  class="opac "
                                  onClick={() => handleSortWrapper("status")}
                                >
                                  
                                  Status
                                  {sortedColumn === "status"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                    <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                    </div>
                                </th> */}
                                <th className="action-buttons">
                                  {/* <div>
                                    
                                    <img
                                    style={shareIconImg}
                                      class="opac-img"
                                      alt="opac"
                                      src={shareBtn}
                                    ></img>
                                  </div> */}
                                  {/* <div>
                                    
                                    <img
                                      class="opac-img"
                                      src={blackEdit}
                                    ></img>
                                  </div> */}
                                  <div>
                                    
                                    <img
                                      class="opac-img"
                                      alt="opac"
                                      src={deleteBtn}
                                    ></img>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              {project.map((pro) => (
                                <>
                                  <tr>
                                    {/* <td class="checked-box"  style={checkOpt}>
                                      
                                      <input
                                        class="form-check-input checkInputProjectList"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                      />
                                    </td> */}
                                    <td  style={ForNameRow}><a  class="redirectBtn" onClick={() => handleEditClick(pro)}>{pro.projectName}</a></td>
                                    <td  style={ForNameRow}>{pro.organizationName}</td>
                                    <td  style={ForRoleRow}>
                                      {getProjectRoleDisplayText(
                                        pro.projectRole
                                      )}
                                    </td>
                                    <td  style={ForDateRow}>{pro.startDate}</td>
                                    <td  style={ForDateRow}>{pro.endDate}</td>
                                    {/* <td  style={ForDateRow}>
                                      {getProjectStatusDisplayText(pro.status)}
                                    </td> */}
                                    <td className="action-buttons">
                                      {/* <div class="opac-action">
                                        
                                        <a class="">
                                          
                                          <img  style={shareIconImg} src={shareBtn}></img>
                                        </a>
                                      </div> */}
                                      {/* <div>
                                        
                                        <a onClick={() => handleEditClick(pro)}>
                                          
                                          <img src={pro.viewable? edit : blackEdit}></img>
                                        </a>
                                      </div> */}
                                      <div>
                                        
                                        <a>
                                          
                                          <img src={pro.viewable ? deleteBtn :blackDelete}></img>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="projectlist-details-table-pagination">
                      <div class="page-no">
                        <div>
                          <Pagination
                            count={pageNumbers}
                            page={pageNumber}
                            onChange={(_event, page) => handlePageChange(page)}
                          />
                        </div>
                      </div>
                      <div class="page-selection">
                        
                        <select
                          className=""
                          onChange={(e) => {
                            handlePageSize(e.target.value);
                          }}
                        >
                          
                          <option>{t('selectPageSize_table')}</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectList;
