import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../helper/auth_helper';
import api from '../../helper/auth_interceptor';
import jwt_decode from 'jwt-decode';
import "./languageSwitcher.css"

const LanguageSwitcher = ({callProjectDetails}) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('');
  

  const shortFormMapping = {
    'ENGLISH': 'en',
    'DUTCH': 'nl'
  };

  const languageMapping = {
    'en': 'ENGLISH',
    'nl': 'DUTCH'
  };

  const updateLanguage = async (lang) => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/updateLanguage`;

    const reqBody = {
      language: languageMapping[lang]
    };

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      await api.put(apiUrl, reqBody, { headers });
    } catch (error) {
      console.error("Error updating language:", error);
    }
  };

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    updateLanguage(selectedLanguage);
    localStorage.setItem('i18nextLng', selectedLanguage);
    setTimeout(() => {
      callProjectDetails();
    }, 3000);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      const fetchUserRole = async () => {
        const user = await getUser();
        if (!user || !user.access_token) {
          return;
        }
        const token = user.access_token;
        const decodedToken = jwt_decode(token);
        const initialLanguage = shortFormMapping[decodedToken.language];
       
        setLanguage(initialLanguage);
       
        i18n.changeLanguage(initialLanguage);
        localStorage.setItem('i18nextLng', initialLanguage);
      };

      fetchUserRole();
    }
  }, [i18n]);

  return (
    <div className="language-row">
      <div className="rows-content">

      <div>
      <select  className="form-control" onChange={changeLanguage} value={i18n.language}>
       
        <option value="en">Engels</option>
        <option value="nl">Nederlands</option>
      </select>
      </div>
    
    </div>
    </div>
  );
};

export default LanguageSwitcher;
