import React, { useState, useEffect, useRef } from "react";
import "./planning.modules.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export const Planning = ({
  planningDetails,
  setPlanningDetails,
  validationCheck,
  projectEndDate
}) => {
  const { t } = useTranslation();
  const [startRangeOpen, setStartRangeOpen] = useState(false);
  const [deliveryRangeOpen, setDeliveryRangeOpen] = useState(false);
  const [rangeStartSelected, setRangeStartSelected] = useState(false);
  const [rangeDeliverySelected, setRangeDeliverySelected] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [startRange, setStartRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  const [deliveryRange, setDeliveryRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const startRangeRef = useRef(null);
  const deliveryRangeRef = useRef(null);

  const handleDeliveryRangeChange = (ranges) => {
    if (ranges.selection.startDate >= startRange[0].startDate) {
      setDeliveryRange([ranges.selection]);
      setRangeDeliverySelected(true);
      if (dateError) {
        setDateError(false);
      }
    } else {
      setDateError(true);
    }
  };

  useEffect(() => {
    setPlanningDetails({
      ...planningDetails,
      desiredStartDateFrom: startRange[0].startDate,
      desiredStartDateTo: startRange[0].endDate,
      desiredDeliveryDateFrom: deliveryRange[0].startDate,
      desiredDeliveryDateTo: deliveryRange[0].endDate,
    });

    const startRangeSelected = startRange.every(
      (range) => range.startDate && range.endDate
    );
    const deliveryRangeSelected = deliveryRange.every(
      (range) => range.startDate && range.endDate
    );
    console.log(startRangeSelected);
    console.log(deliveryRangeSelected);

  }, [startRange, deliveryRange]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startRangeOpen &&
        startRangeRef.current &&
        !startRangeRef.current.contains(event.target)
      ) {
        setStartRangeOpen(false);
      }

      if (
        deliveryRangeOpen &&
        deliveryRangeRef.current &&
        !deliveryRangeRef.current.contains(event.target)
      ) {
        setDeliveryRangeOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startRangeOpen, deliveryRangeOpen]);

  console.log("projectEndDate",projectEndDate)
  return (
    <div className="form-head">
      <section class={`accordion `}>
        <div class="tab plan">
          <input type="checkbox" name="accordion-1" id="cb9" checked />
          <label htmlFor="cb9" className="tab-label form-head quote-detail">
            <h2>{t('heading_planningQuote')}</h2>
          </label>
          <div className="tab-content">
            <div className="planning-headers">
              <label>{t('text1_planingQuote')}</label>
            </div>
            <div className="planning-content">
              <div className="first-row">
                <div className="rows-content">
                  <input className="date form-control" placeholder={projectEndDate} disabled type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
