import React, { useState, useEffect, useRef } from "react";
import api from "../../helper/auth_interceptor";
import { getUser, login } from "../../helper/auth_helper";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export const MyProjectsList = () => {

    const ForNameRow = { width: "35%", textAlign: "left" };
    const ForStausRow = { width: "25%", textAlign: "center" };
    const [project, setProject] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {

    }, []);

    const requestBody = useRef({
        status: null,
        startDate: null,
        endDate: null,
        searchText: null,
    });

    const getWorkflowStatusDisplayText = (role) => {
        const roleMapping = {
        PROJECT_READY: t('project_ready'),
        PROJECT_STARTED: t('project_started'),
        PROJECT_COMPLETED : t('project_completed'),
        };
        return roleMapping[role] || role;
      };

    async function fetchMyProjectDetails(page) {
        const user = await getUser();
        if (!user) {
            login();
            return;
          }

        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/getMyProjects`;

        const headers = {
            Authorization: `Bearer ${user.access_token}`,
        };

        try {
            const response = await api.get(apiUrl, {
                headers,
            });
            setProject(response.data.myProjectsList);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchMyProjectDetails();
    }, []);

    if (!project) {
        return <div>Loading...</div>;
    }

    const handleEditClick = (rowData) => {       
        navigate("/editProject/" + rowData.id, { state: { rowData: project } });
    };

    return (
        <div className="projectlist-details-table-content">
            <div className="table-content">
                <div className="list">
                    <table>
                        <thead>
                            <tr className="record-row">

                                <th style={ForNameRow} class="opac " >{t("project_table")}</th>
                                <th style={ForNameRow} class="opac ">{t("projectLeader_table")}</th>
                                <th style={ForStausRow} class="opac ">{t("workflowStatus_table")}</th>

                            </tr>
                        </thead>
                        <tbody>

                            {project.slice(0, 5).map((pro) => (
                                <>
                                    <tr>

                                        <td style={ForNameRow}><a class="redirectBtn" onClick={() => handleEditClick(pro)}>{pro.projectName}</a></td>
                                        <td style={ForNameRow}>{pro.projectLeaderName}</td>
                                        <td style={ForStausRow}>
                                          {pro.projectStatus ? (getWorkflowStatusDisplayText(pro.projectStatus)) : ''}
                                        </td>

                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}
