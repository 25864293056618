import React, { useState, useEffect, useRef } from "react";
import "./planning.modules.css";
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";

export const Planning = ({ newQuoteDetails, offerDetails }) => {
  const [partnerStartDateError, setPartnerStartDateError] = useState(false);
  const [partnerDeliveryDateError, setPartnerDeliveryDateError] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const isStartDateValid = moment(offerDetails.missionStartDateBegin).isSameOrBefore(offerDetails.missionStartDateEnd);
    const isDeliveryDateValid = moment(offerDetails.deliveryDateBegin).isSameOrBefore(offerDetails.deliveryDateEnd);

    setPartnerStartDateError(!isStartDateValid);
    setPartnerDeliveryDateError(!isDeliveryDateValid);
  }, [offerDetails]);

  return (
    <div className="form-head">
      <section class="accordion">
        <div class="tab plan">
          <input type="checkbox" name="accordion-1" id="cb10" />
          <label htmlFor="cb10" className="tab-label form-head quote-detail">
            <h2>{t('heading_planningQuote')}</h2>
          </label>
          <div className="tab-content">
            <div className="planning-content drone">
            
              <div class="first-row drone">
                <div class="rows-content">
                  <label>{t('dateByPartner')}</label>
                  <input
                    className={`date form-control ${partnerDeliveryDateError ? 'error' : ''}`}
                    placeholder={moment(offerDetails.deliveryDateBegin).format("DD-MM-YYYY") + "  -  " + moment(offerDetails.deliveryDateEnd).format("DD-MM-YYYY")}
                    disabled
                    type="text"
                  />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
