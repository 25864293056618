import { UserManager } from "oidc-client";

const settings={
  authority: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/SupRmen`,
  client_id: "frontend_client",
  redirect_uri: process.env.REACT_APP_FRONT_END_REDIRECT_URL,
  response_type: 'code',
  scope: "openid profile",
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_FRONT_END_REDIRECT_URL,
  post_logout_redirect_uri: window.location.href, 
  revokeAccessTokenOnSignout: true
}

const userManager = new UserManager(settings);

export const getUser = async () => {
  const user = await userManager.getUser();
  console.log(user);
  
  return userManager.getUser();
}

export const login = () => {
    const redirectUrl = window.location.pathname;
    return userManager.signinRedirect({state:{redirectUrl:redirectUrl}});
}

export const logout = () => {
    return userManager.signoutRedirect();
}

export const loginRedirect= ()=>{
    return userManager.signinRedirectCallback()
}

