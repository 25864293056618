import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUser } from "../../helper/auth_helper";
import { Card } from "@mui/material";

const QuoteDetails = () => {
  const { id } = useParams();
  const [quote, setQuote] = useState(null);

  const fetchQuoteDetails = async (userData) => {
    const url = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/${id}`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        setQuote(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser().then((user) => {
      
      if (user) {
        fetchQuoteDetails(user);
      }
    });
  }, [id]);

  if (!quote) {
    return <div>Loading...</div>;
  }

  const renderNestedObjects = (obj) => {
    return (
      <div>
        <strong>ID:</strong> {obj.id}
        <br />
        <strong>Start Date:</strong> {obj.startDate}
        <br />
        <strong>End Date:</strong> {obj.endDate}
        <br />
        <strong>Client Name:</strong> {obj.clientName}
        <br />
        <strong>Contact:</strong> {obj.contact}
        <br />
        <strong>Owner:</strong> {obj.owner}
        <br />
        <strong>Status:</strong> {obj.currentStatus}
        <br />
        <strong>Quote Inspection Items:</strong>
        {obj.quoteInspectionItems.map((item) => (
          <div key={item.quoteInspectionItemId}>
            <strong>Quote ID:</strong> {item.quoteInspectionItemId.quoteId}
            <br />
            <strong>Inspection Item ID:</strong>
            {item.quoteInspectionItemId.inspectionItemId}
            <br />
            <strong>Quantity:</strong> {item.quantity}
            <br />
            <strong>Inspection Item ID:</strong> {item.inspectionItem.id}
            <br />
            <strong>Inspection Item Name:</strong> {item.inspectionItem.name}
            <br />
            <strong>Inspection Item Cost:</strong> {item.inspectionItem.cost}
            <br />
            <br />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card>
      <div>
        <h2>Quote Details</h2>
        {renderNestedObjects(quote)}
      </div>
    </Card>
  );
};

export default QuoteDetails;
