import React, { useEffect, useState } from "react";
import { getUser } from "../../helper/auth_helper";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Circles } from "react-loader-spinner";
import jwt_decode from "jwt-decode";
import DatePicker from "react-datepicker";
import api from "../../helper/auth_interceptor";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./CreateUser.modules.css";
import plus from "../../images/Frame.png";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import "react-datepicker/dist/react-datepicker.css";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

function CreateUser(isSuprAdmin) {
  const [userDetails, setUserDetails] = useState({
    lastName: "",
    firstName: "",
    language: null,
    roleType: "",
    email: "",
    jobTitle: "",
    userStartDate: "",
    userEndDate: "",
    contactNo: "",
    status: "",
  });

  const [orgId, setOrgId] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const format1 = "DD-MM-YYYY";
  const sDate = moment(startDate).format(format1);
  const eDate = moment(endDate).format(format1);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await getUser();

    const requiredFields = [
      "lastName",
      "firstName",
      "roleType",
      "email",
      "status",
      "userEndDate",
    ];

    const fieldLabels = {
      lastName: t('lastName'),
      firstName: t('firstName'),
      roleType: t('role'),
      email: t('emailAddress'),
      status: t('status'),
      userEndDate: t('endDate'),
      
    };

    const newValidationErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!userDetails[field]) {
        newValidationErrors[field] = `${fieldLabels[field]} ${t('required_errorMessage')}`;
        hasErrors = true;
      }
    });

    if (!endDate) {
      newValidationErrors["userEndDate"] = `${t('endDate')} ${t('required_errorMessage')}`;
      hasErrors = true;
    }

 

    if (moment(endDate).isSameOrBefore(startDate)) {
      newValidationErrors["userEndDate"] =
      `${t('endDate_errorMessage')}`
      hasErrors = true;
    }

    if (hasErrors) {
      setValidationErrors(newValidationErrors);
      return;
    }

 
   
    setLoading(true);
    const formData = {
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
      language: userDetails.language,
      roleType: userDetails.roleType,
      email: userDetails.email,
      jobTitle: userDetails.jobTitle,
      userStartDate: sDate,
      userEndDate: eDate,
      contactNo: userDetails.contactNo,
      status: userDetails.status,
    };

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/${orgId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, formData, {
        headers,
      });
      if (response) {
        toast(`${t('user_Created')}`);
        navigate("/manageUser");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      } else {
        toast.error(`${('errorInviting_errorMessage')}`)
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneNoChange = (inputValue) => {
    const validPhoneNo = /^[\d+()-]*$/.test(inputValue);

    if (validPhoneNo || inputValue === "") {
      setUserDetails({
        ...userDetails,
        contactNo: inputValue,
      });
    } else {
      const newValidationErrors = {};
      newValidationErrors["contactNo"] =
      `${t('phoneNo_errorMessage')}`
      setValidationErrors(newValidationErrors);
    }
  };

  useEffect(() => {
    async function getOrgId() {
      const user = await getUser();
      if (!user || !user.access_token) {
        return;
      }
      const token = user.access_token;
      const decodedToken = jwt_decode(token);

      setOrgId(decodedToken.orgId);
    }

    getOrgId();
  }, []);

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img class="sidebar-toggle" alt="toggle" src={hamburger}></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>

          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>{t('pageHeader_createUser')}</h1>
                </div>
                <div class="user">
                  
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t('pageHeaderBtn_createUser')}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="user-details-record">
                    <div class="user-details">
                      <div className="form-head user-details">
                        <h2 className="pageHeading">{t('tableHeading_createUser')}</h2>
                      </div>
                      <div class="user-details-content">
                        <form onSubmit={handleSubmit}>
                          <div className="first-row">
                            <div
                              className={`rows-content ${
                                validationErrors.firstName ? "error" : ""
                              }`}
                            >
                              
                              <label>
                                {t('firstName')} <span className="required">*</span>
                              </label>
                              <input
                                class="form-control"
                                value={userDetails.firstName}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    firstName: e.target.value,
                                  })
                                }
                                type="text"
                              />
                              {validationErrors.firstName && (
                                <span className="error-message">
                                  {validationErrors.firstName}
                                </span>
                              )}
                            </div>
                            <div
                              className={`rows-content ${
                                validationErrors.lastName ? "error" : ""
                              }`}
                            >
                              
                              <label>
                                {t('lastName')} <span className="required">*</span>
                              </label>
                              <input
                                class="form-control"
                                value={userDetails.lastName}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    lastName: e.target.value,
                                  })
                                }
                                type="text"
                              />
                              {validationErrors.lastName && (
                                <span className="error-message">
                                  {validationErrors.lastName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="second-row">
                            <div
                              className={`rows-content ${
                                validationErrors.email ? "error" : ""
                              }`}
                            >
                              
                              <label>
                                {t('emailAddress')}
                                <span className="required">*</span>
                              </label>
                              <input
                                class="form-control"
                                value={userDetails.email}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    email: e.target.value,
                                  })
                                }
                                type="text"
                              />
                              {validationErrors.email && (
                                <span className="error-message">
                                  {validationErrors.email}
                                </span>
                              )}
                            </div>
                            <div
                              class={`rows-content ${
                                validationErrors.contactNo ? "error" : ""
                              }`}
                            >
                              
                              <label>{t('phoneNo')} </label>
                              <input
                                class="form-control"
                                value={userDetails.contactNo}
                                onChange={(e) =>
                                  handlePhoneNoChange(e.target.value)
                                }
                                type="text"
                                maxLength={18}
                              />
                              {validationErrors.contactNo && (
                                <span className="error-message">
                                  {validationErrors.contactNo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="third-row">
                            <div
                              className={`rows-content ${
                                validationErrors.roleType ? "error" : ""
                              }`}
                            >
                              
                              <label>
                                {t('role')} <span className="required">*</span>
                              </label>
                              <div>
                                
                                <select
                                  className="form-control"
                                  value={userDetails.roleType}
                                  onChange={(e) =>
                                    setUserDetails({
                                      ...userDetails,
                                      roleType: e.target.value,
                                    })
                                  }
                                >
                                  
                                  <option>{t('placeHolder1_createUser')}</option>
                                  {isSuprAdmin?.suprmenAdmin && (<option value="SUPRMEN_ADMIN">
                                  {t('suprmenAdmin_dropDownMenu')}
                                  </option>
                                  )}
                                  <option value="ORG_ADMIN">{t('orgAdmin_dropDownMenu')}</option>
                                  <option value="REGULAR">{t('regular_dropDownMenu')}</option>
                                  <option value="VIEWER">{t('viewer_dropDownMenu')}</option>
                                </select>
                                {validationErrors.roleType && (
                                  <span className="error-message">
                                    {validationErrors.roleType}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div
                              className={`rows-content ${
                                validationErrors.status ? "error" : ""
                              }`}
                            >
                              
                              <label>
                                {t('status')} <span className="required">*</span>
                              </label>
                              <div>
                                
                                <select
                                  className="form-control"
                                  value={userDetails.status}
                                  onChange={(e) =>
                                    setUserDetails({
                                      ...userDetails,
                                      status: e.target.value,
                                    })
                                  }
                                >
                                  
                                  <option>{t('placeHolder2_createUser')}</option>
                                  <option value="Active">{t('active_dropDownMenu')}</option>
                                  <option value="Inactive">{t('inactive_dropDownMenu')}</option>
                                </select>
                                {validationErrors.status && (
                                  <span className="error-message">
                                    {validationErrors.status}
                                  </span>
                                )}
                              </div>
                            </div>
                            
                          </div>
                          <div class="fourth-row">
                            <div className="rows-content">
                              
                              <label>
                                {t('startDate')} <span className="required">*</span>
                              </label>
                              <div className="date form-control">
                                <DatePicker
                                  class="form-control"
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                    setUserDetails({
                                      ...userDetails,
                                      userStartDate: date,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className={`rows-content ${
                                validationErrors.userEndDate ? "error" : ""
                              }`}
                            >
                              
                              <label>
                              {t('endDate')} <span className="required">*</span>
                              </label>
                              <div className="date form-control">
                                <DatePicker
                                  class="form-control"
                                  selected={endDate}
                                  onChange={(date) => {
                                    setEndDate(date);
                                    setUserDetails({
                                      ...userDetails,
                                      userEndDate: date,
                                    });
                                  }}
                                />
                              </div>
                              {validationErrors.userEndDate && (
                                <span className="error-message">
                                  {validationErrors.userEndDate}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="fifth-row">
                            <div class="rows-content">
                              
                              <label>{t('language_createEditUser')} </label>
                              <div>
                                
                                <select
                                  className="form-control"
                                  value={userDetails.language}
                                  onChange={(e) =>
                                    setUserDetails({
                                      ...userDetails,
                                      language: e.target.value,
                                    })
                                  }
                                >
                                  
                                  <option value>{t('placeHolder3_createUser')}</option>
                                  <option value="ENGLISH">English</option>
                                  <option value="DUTCH">Nederlands</option>
                                </select>
                              </div>
                            </div>
                            <div class="rows-content">
                              
                              <label>{t('jobTitle_createEditUser')} </label>
                              <input
                                class="form-control"
                                value={userDetails.jobTitle}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    jobTitle: e.target.value,
                                  })
                                }
                                type="text"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-button">
                    
                    <button
                      className="btn-back"
                      onClick={() => navigate("/manageUser")}
                    >
                      
                      <span>{t('backBtn')}</span>
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-save"
                    >
                      
                      <span>{t('saveBtn')} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
