import React, { useEffect, useState } from "react";
import "./outsideExtras.modules.css";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import BIM100 from "../../../../images/BIM 100.png";
import BIM200 from "../../../../images/BIM 200.png";
import BIM300 from "../../../../images/BIM 300.png";
import BIM400 from "../../../../images/BIM 400.png";
import BIM500 from "../../../../images/BIM 500.png";
import { useTranslation } from "react-i18next";

const imageMap = {
  "BIM 100": BIM100,
  "BIM 200": BIM200,
  "BIM 300": BIM300,
  "BIM 400": BIM400,
  "BIM 500": BIM500,
};

export const OutsideExtrasAccord = ({
  outsideExtras,
  setOutsideExtras,
  setComponentCompletion,
  validationCheck,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);

  const descriptionMap = {
    "BIM 100": t("bim100"),
    "BIM 200": t("bim200"),
    "BIM 300": t("bim300"),
    "BIM 400": t("bim400"),
    "BIM 500": t("bim500"),
  };

  const briefMap = {
    "BIM 100": t("bim100_breif"),
    "BIM 200": t("bim200_breif"),
    "BIM 300": t("bim300_breif"),
    "BIM 400": t("bim400_breif"),
    "BIM 500": t("bim500_breif"),
  };

  const mainImgDiv = { display: "flex", flexDirection: "column", width: "19%" };
  const imgCSS = {
    width: "auto",
    objectFit: "contain",
    position: "relative",
    top: "3px",
  };
  const checkInputItem = {
    position: "relative",
    top: "5px",
    marginRight: "10px",
  };

  const handleValueChange = (selectedId, selectedName) => {
    let updatedProducts;
    const isAlreadySelected = outsideExtras.some(
      (extras) => extras.id === selectedId
    );

    if (isAlreadySelected) {
      updatedProducts = outsideExtras.filter(
        (extras) => extras.id !== selectedId
      );
    } else {
      updatedProducts = [
        ...outsideExtras,
        { id: selectedId, name: selectedName },
      ];
    }

    setOutsideExtras(updatedProducts);
    setIsAnyOptionSelected(updatedProducts.length > 0);
    setComponentCompletion(updatedProducts.length > 0);

    return updatedProducts;
  };

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/outsideExtraServices`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="form-head">
      <section className={`accordion ${validationCheck ? "no-selection" : ""}`}>
        <div className="tab">
          <input type="checkbox" name="accordion-1" id="cb6" />
          <label htmlFor="cb6" className="tab-label form-head quote-detail">
            <h2>{t("exteriorExtras_outsideExtrasQuote")}</h2>
          </label>
          <div className="tab-content record">
            <div>
              <div className="check-header">
                <div className="check-header-title">
                  <input
                    className="CheckInput"
                    type="checkbox"
                    checked={isAnyOptionSelected}
                  />
                  <label htmlFor="Services">
                    {t("extraServices_outsideExtrasQuote")}
                  </label>
                </div>
              </div>

              <div>
                {data.length > 0 && (
                  <div className="check first-item">
                    <input
                      className="CheckInput"
                      type="checkbox"
                      value={data[0].id}
                      onChange={() =>
                        handleValueChange(data[0].id, data[0].name)
                      }
                    />
                    <label htmlFor="BIM">{data[0].name}</label>
                  </div>
                )}
              </div>

              <div className="check-options extras">
                {data.slice(1).map((item, index) => (
                  <div
                    className={`check ${index === 0 ? "second-item" : ""}`}
                    style={mainImgDiv}
                    key={item.id}
                  >
                    <div>
                      <input
                        style={checkInputItem}
                        type="checkbox"
                        value={item.id}
                        onChange={() => handleValueChange(item.id, item.name)}
                      />
                      <label htmlFor="BIM">{item.name}</label>
                    </div>
                    
                    <img
                      style={imgCSS}
                      src={imageMap[item.name]}
                      alt={item.name}
                    />
                    <p>{briefMap[item.name]}</p>
                    <p>{descriptionMap[item.name]}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && (
        <div className="message">
          {t("pleaseChooseAtLeastOneField_errorMessage")}
        </div>
      )}
    </div>
  );
};
