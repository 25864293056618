import React, { useEffect, useState } from "react";
import "./insideSummary.modules.css";
import { useTranslation } from 'react-i18next';
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";


export const InsideProductAccord = ({insideProduct, setInsideProduct, setComponentCompletion, validationCheck}) => {
  const [data, setData] = useState([]);
  const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
  const { t } = useTranslation();

  const handleValueChange = (selectedId,selectedName) => {
    let updatedProducts;

    // Check if the selectedId is already in the array
    const isAlreadySelected = insideProduct.some((product) => product.id === selectedId);
  
    if (isAlreadySelected) {
      // If the checkbox is unchecked, remove the item from the array
      updatedProducts = insideProduct.filter((product) => product.id !== selectedId);
    } else {

      updatedProducts = [...insideProduct, { id: selectedId, name: selectedName }];
    }
    
      setInsideProduct(updatedProducts);

      setIsAnyOptionSelected(updatedProducts.length > 0);

      setComponentCompletion(updatedProducts.length > 0);

      return updatedProducts;
    
  };

  

  const getData = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/quote/insideProduct`;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      }
    }
  };

  useEffect(() => {
    getData();
   
  }, []);
  return (
    <div className="form-head">
      <section  class={`accordion ${validationCheck ? 'no-selection' : ''}`}>
        <div class="tab">
          <input className="CheckInput" type="checkbox" name="accordion-1" id="cb8" />
          <label for="cb8" class="tab-label form-head quote-detail">
            <h2>{t('interiorProduct_insideProductQuote')}</h2>
          </label>
          <div class="tab-content record">
            <div>
              <div class="check-header">
                <div class="check-header-title">
                  <input  className="CheckInput" type="checkbox" id="Extra" checked={isAnyOptionSelected} />
                  <label for="Extra">{t('interiorProducts_insideProductQuote')}</label>
                </div>
              </div>
              <div class="check-options summary">
                {data?.map((item) => (
                  <div class="check">
                    <input  className="CheckInput" type="checkbox" value={item.id} onChange={() => handleValueChange(item.id,item.name)} />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && <div className="message">{t('pleaseChooseAtLeastOneField_errorMessage')}</div>}
    </div>
  );
};
