import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import api from "../../helper/auth_interceptor";
import { handleSort } from "./handleSort";
import { getUser } from "../../helper/auth_helper";
import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import edit from "../../images/edit.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import deleteBtn from "../../images/trash.png";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./manageOrg.modules.css";
import { toast } from "react-toastify";
import createicon from "../../images/create-icon.png";
import moment from "moment";
import blackEdit from "../../images/blackedit.png";
import blackDelete from "../../images/blackDelete.png";
import searchbar from "../../images/search-bar.png";
import useExport from "../shared/useExport";
import date from "../../images/date-icon.png";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

function ManageOrganisation({ suprmenAdmin, orgAdmin, viewer, regular }) {
  const [showCalendar, setShowCalendar] = useState(false);
  const dateRangeRef = useRef(null);
  const checkOpt = { width: "5%" };
  const ForNameRow = { width: "30%", textAlign: "left" };
  const ForCityRow = { width: "30%", textAlign: "left" };
  const ForDateRow = { width: "20%", textAlign: "left" }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [userData, setUser] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [orgs, setOrgs] = useState([]);
  const navigate = useNavigate();

  const requestBody = useRef({
    username: null,
    firstName: null,
    prefix: null,
    lastName: null,
    email: null,
    status: null,
    jobTitle: null,
    startDate: null,

    role: null,
  });
  const { t } = useTranslation();
  const [sortedColumn, setSortedColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const handleSortWrapper = async (column, page) => {
    await handleSort(
      column,
      page,
      sortedColumn,
      isAscending,
      setSortedColumn,
      setIsAscending,
      currentPage,
      selectedPageSize,
      requestBody,
      setOrgs,
      setPageNumbers
    );
  };

  const toggleClearFilter = () => {
    window.location.reload();
  };

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchOrgDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);

  const pageNumber = currentPage.current;

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchOrgDetails();
  };

  async function fetchOrgDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    const selectedSize = selectedPageSize.current;

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/filter?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });

      setOrgs(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchOrgDetails();
    if (orgAdmin || viewer || regular) {
      toast.error("You do not have permission to access this page.");
      navigate("/");
    }
  }, [viewer, navigate]);

  if (!orgs) {
    return <div>Loading...</div>;
  }

  const handleEditClick = (rowData) => {
    navigate("/editOrg/" + rowData.id, { state: { rowData: orgs } });
  };

  const handleDeleteOrganization = async (rowData) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this organization?"
    );

    if (confirmDelete) {
      const user = await getUser();
      const delApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/${rowData.id}`;
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };

      try {
        await api.delete(delApi, {
          headers,
        });

        const updatedOrgs = orgs.filter((org) => org.id !== rowData.id);
        setOrgs(updatedOrgs);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          await fetchUserDetails(rowData.id);
          const userNames = userData
            .map((userData) => {
              return `${userData.firstName} ${userData.lastName}`;
            })
            .join("\n");

          toast.error(
            `Unable to delete organization. Please delete all the users first. \nList of Users:\n${userNames}`
          );
        } else {
          console.error("Error deleting organization:", error);
        }
      }
    }
  };

  async function fetchUserDetails(rowData) {
    const user = await getUser();
    const pageForUrl = currentPage.current;
    const selectedSize = selectedPageSize.current;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/filter/${rowData}?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });

      setUser(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      startDate: startDate,
      endDate: endDate,
    };
    fetchOrgDetails();
  };

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;

    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchOrgDetails();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { handleExport } = useExport();

  const handleClickExport = async () => {
    const sortedOrder = isAscending ? "asc" : "desc";
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/export?sortBy=${sortedColumn}&sortOrder=${sortedOrder}`;
    const fileName = "OrganizationsList";
    handleExport(fileName, apiUrl, requestBody.current);
  };

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input
          id="slide-sidebar"
          type="checkbox"
          className="checkInputManageOrgList"
          role="button"
        />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("pageHeader_manageOrg")}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t("pageHeaderBtn_manageOrg")}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="organizationlist-record">
                  <div class="organizationlist-details">
                    <div className="form-head list-org">
                      <div>
                        <h2 className="pageHeading">
                          {t("tableHeading_manageOrg")}
                        </h2>
                      </div>
                     
                    </div>
                    <div class="organizationlist-details-content">
                      <div class="content-options">
                      <div class="search-option searchfilter">
                          <div class="search-input">
                            <img src={searchbar} alt="home icon" />
                            <input
                              className="checkInputManageOrgList"
                              type="text"
                              placeholder={t('search')}
                              value={requestBody.searchText}
                              onChange={handleSearchFormSubmit}
                            />
                          </div>
                        </div>

                        <div class="content-button date filterIconsMainDiv">
                          <img
                            src={date}
                            alt="date icon"
                            className="dateicon"
                          />
                          <button
                            className="btn-date"
                            onClick={() => setShowCalendar(!showCalendar)}
                          >
                            <span>{t("dateFilter_manageOrg")}</span>
                          </button>
                          <div className="dateRange" ref={dateRangeRef}>
                            {showCalendar && (
                              <DateRangePicker
                                ranges={selectedDateRange}
                                onChange={handleDateChange}
                              />
                            )}
                          </div>
                        </div>
                        <div class="content-button clear filterIconsMainDiv">
                          <button class="btn-clear" onClick={toggleClearFilter}>
                            <span>{t("clearFilter_manageOrg")}</span>
                          </button>
                        </div>
                        <div class="export">
                          <button className="btn" onClick={handleClickExport}>
                            <span>{t("exportBtn_manageOrg")}</span>
                          </button>
                        </div>
                      </div>

                      <div class="right-buttons">
                      {!viewer && !regular && (suprmenAdmin || orgAdmin) ? (
                        <div class="create-org-btn">
                          <button class="btn quoteBtns" type="button">
                            <a href="/createOrganization" className="quotelink">
                              <img src={createicon} alt="create icon"></img>{" "}
                             <span>{t("tableHeadingBtn_manageOrg")}</span>
                            </a>
                          </button>
                        </div>
                      ) : null}
                       
                      </div>
                    </div>
                    <div class="organizationlist-details-table-content">
                      <div class="table-content">
                        <div className="list">
                          <table>
                            <thead>
                              <tr className="record-row">
                                <th
                                  style={ForNameRow}
                                  class="opac"
                                  onClick={() => handleSortWrapper("name")}
                                >
                                  {t("organisationName_table")}
                                  {sortedColumn === "name"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                  style={ForCityRow}
                                  class="opac"
                                  onClick={() => handleSortWrapper("city")}
                                >
                                  {t("city_table")}
                                  {sortedColumn === "city"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                  style={ForDateRow}
                                  class="opac"
                                  onClick={() => handleSortWrapper("startDate")}
                                >
                                 {t("startDate_table")} 
                                  {sortedColumn === "startDate"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>
                                <th
                                  style={ForDateRow}
                                  class="opac"
                                  onClick={() => handleSortWrapper("endDate")}
                                >
                                 {t("endDate_table")} 
                                  {sortedColumn === "endDate"
                                    ? isAscending
                                      ? ""
                                      : ""
                                    : ""}
                                  <div class="table-arrow">
                                    <div class="arrow-up">&#9650;</div>
                                    <div class="arrow-down">&#9660;</div>
                                  </div>
                                </th>

                                <th className="action-buttons">
                                  <div>
                                    <img
                                      src={deleteBtn}
                                      alt="black delete"
                                    ></img>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {orgs.map((org) => (
                                <>
                                  <tr>

                                    <td style={ForNameRow}>
                                      {" "}
                                      <a
                                        href=""
                                        onClick={() => handleEditClick(org)}
                                      >
                                        {org.name}
                                      </a>
                                    </td>
                                    <td style={ForCityRow}>{org.city}</td>
                                    <td style={ForDateRow}>{org.startDate}</td>
                                    <td style={ForDateRow}>{org.endDate}</td>
                                    <td className="action-buttons">
                                      <div>
                                        <a
                                          onClick={() =>
                                            handleDeleteOrganization(org)
                                          }
                                        >
                                          <img
                                            src={deleteBtn}
                                            alt="delete button"
                                          ></img>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="organizationlist-details-table-pagination">
                      <div class="page-no">
                        <div>
                          <Pagination
                            count={pageNumbers}
                            page={pageNumber}
                            onChange={(_event, page) => handlePageChange(page)}
                          />
                        </div>
                      </div>
                      <div class="page-selection">
                        <select
                          onChange={(e) => {
                            handlePageSize(e.target.value);
                          }}
                        >
                          <option>{t("selectPageSize_table")}</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageOrganisation;
