import { useEffect, useState, useRef } from 'react';
import {loginRedirect} from '../helper/auth_helper';
import { useNavigate } from "react-router-dom";





const useAuth = () => {



    const isRun = useRef(false);

    const [token, setToken] = useState(null);

    const [isLogin, setLogin] = useState(false);

    const [user,setUser] = useState(null);
    const navigate = useNavigate();

   
    useEffect(() => {


      if (isRun.current) return;


  
      isRun.current = true;
      loginRedirect().then((user)=>{
           
            setUser(user);
            setLogin(true);

            setToken(user.access_token);
            localStorage.setItem("user",JSON.stringify(user));
            navigate(user.state.redirectUrl);
        }).catch(function (e) {
            console.error(e);
        });
    }, []);

  
    return [isLogin, user];



  };






  export default useAuth;
