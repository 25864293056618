import React from "react";
import Sidebar from "./sidebar";
import Protected from "../Protected";

export const ProtectedSidebar = () => {
  return (
    <>
      <Protected Component={Sidebar} />
      {/* ... other components */}
    </>
  );
};

