import React, { useState } from "react";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';


const EditModal = ({ id, orgId, editedBuilding, closeModal, reloadAdd }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState({
     name: editedBuilding ? editedBuilding.name : "",
    pandId: editedBuilding ? editedBuilding.pandId : "",
    streetName: editedBuilding ? editedBuilding.streetName : "",
    city: editedBuilding ? editedBuilding.city : "",
    postcode: editedBuilding ? editedBuilding.postcode : "",
    country: editedBuilding ? editedBuilding.country : "",
    visualizationModel: editedBuilding ? editedBuilding.visualizationModel : false,
  });

  

  const handleChange = (field, value) => {
    setRows({
      ...rows,
      [field]: value,
    });
  };

  const submitBuildingInput = async () => {
    const user = await getUser();
    const updateApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/${editedBuilding.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    const requestBody = {
      ...rows,
      projectId: Number(id),
      organizationId: Number(orgId),
    };

    try {
      await api.put(updateApi, requestBody, {
        headers,
      });
      closeModal();
      toast.success(`${t('buildingDetailsUpdated')}`);
      reloadAdd()
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
        <div class="userdiv">
            <div className="addressDiv-content">
          <table>
            <thead>
              <tr>
                <th>{t('name_portalUser')} <span className="required">*</span></th>
                <th>{t('pandId_editProjectTable')}</th>
                <th>{t('streetName')} <span className="required">*</span></th>
                <th>{t('city')} <span className="required">*</span></th>
                <th>{t('postCode')} <span className="required">*</span></th>
                <th>{t('Country')} <span className="required">*</span></th>
                <th>{t('visualizationModel_editProjectTable')} <span className="required">*</span></th>
                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    value={rows.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.pandId}
                    onChange={(e) => handleChange("pandId", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.streetName}
                    onChange={(e) => handleChange("streetName", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.postcode}
                    onChange={(e) => handleChange("postcode", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.country}
                    onChange={(e) => handleChange("country", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td >
                  <input
                    className="checkStyle"
                    type="checkbox"
                    checked={rows.visualizationModel}
                    onChange={(e) =>
                      handleChange("visualizationModel", e.target.checked)
                    }
                  />
                </td>
                <td>
                 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          className="btn btn-success modal-save-button"
          onClick={submitBuildingInput}
        >
          
          {t('submit')}
        </button>
        </div>
       
     
    </div>
  );
};

export default EditModal;
