// src/components/QuoteDetails.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './quoteAccordian.modules.css';
import { getUser } from '../../../../helper/auth_helper';
import api from '../../../../helper/auth_interceptor';
import { toast } from 'react-toastify';

export const QuoteDetails = ({
  suprmenAdmin,
  quoteDetails,
  setQuoteDetails,
  setComponentCompletion,
  validationCheck,
}) => {
  const { t } = useTranslation(); // Use the useTranslation hook to access translations
  const [projectData, setProjectData] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [projectId, setProjectId] = useState();
  const [dataClientName, setClientName] = useState();
  const [dataEmail, setEmail] = useState();
  const [dataContactNo, setContactNo] = useState();

  const reqrdLabel = { top: '5px', position: 'relative', color: 'red', fontSize: '17px' };

  const projectInfo = (id) => {
    const selectedProject = projectData.find((project) => project.id === parseInt(id));
    if (selectedProject) {
      setClientName(selectedProject.organization.name);
      setEmail(selectedProject.projectLeader.email);
      return {
        clientName: selectedProject.organization.name,
        email: selectedProject.projectLeader.email,
      };
    } else {
      return null;
    }
  };

  const handleValueChange = (newProjectDetailsValues) => {
    setQuoteDetails(newProjectDetailsValues);
    const allFieldsFilled = newProjectDetailsValues.projectId;
    setComponentCompletion(allFieldsFilled);
  };

  const getProject = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project`;

    try {
      const response = await api.get(apiUrl, { headers });
      if (response) {
        setProjectData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join('\n ');
        toast.error(errorMessages);
      }
    }
  };

  const mappedData = projectData.map(({ id, name }) => ({ id, name }));

  const validateContact = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d+$/;
    if (emailRegex.test(value)) {
      setIsValidEmail(true);
      return true;
    } else if (phoneRegex.test(value)) {
      setIsValidEmail(false);
      return false;
    } else {
      setIsValidEmail(true);
      return true;
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <div className="form-head">
      <section className={`accordion ${validationCheck ? 'no-selection' : ''}`}>
        <div className="tab">
          <input type="checkbox" name="accordion-1" id="cb1" checked />
          <label htmlFor="cb1" className="tab-label form-head quote-detail">
            <h2>{t('quoteDetails_quoteOverviewQuote')}</h2>
          </label>
          <div className="tab-content">
            <div className="quote-content">
              <div className="first-row">
                <div className="rows-content">
                  <label>{t('clientName_quoteOverviewQuote')}</label>
                  <input className="form-control" placeholder={t('clientName_quoteOverviewQuote')} value={dataClientName} disabled />
                </div>
                <div className="rows-content">
                  <label>
                    {t('projectName_quoteOverviewQuote')} <span style={reqrdLabel}>*</span>
                  </label>
                  <select
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const selectedItem = mappedData.find((item) => item.id === selectedId);
                      const selectedName = selectedItem ? selectedItem.name : '';
                      setProjectId(e.target.value);
                      projectInfo(e.target.value);
                      handleValueChange({
                        ...quoteDetails,
                        projectId: e.target.value,
                        projectName: selectedName,
                      });
                    }}
                  >
                    <option disabled selected>
                      {t('selectProject_quoteOverviewQuote')}
                    </option>
                    {mappedData.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="second-row">
                <div className="rows-content">
                  <label>{t('emailAddress_quoteOverviewQuote')}</label>
                  <input className="form-control" placeholder="abc@gmail.com" value={dataEmail} disabled />
                </div>
                <div className="rows-content"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {validationCheck && <div className="message">{t('pleaseFillFields_errorMessage')}</div>}
    </div>
  );
};
